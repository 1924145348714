// const pre = `http://localhost:2975/`;

// const pre = `http://148.251.88.109:8080/EconetBigCash/`;
const pre = `https://api.econetbigcash.com/`;
// const pre=`http://5.189.166.187:5551/BigcashPortalLive/`;
// const pre=`http://91.205.172.123:2952/`
//

const sendServicesDataApi = `${pre}api/v1/auth/sendServicesData`;
export { sendServicesDataApi };

const sendWinnersApi = `${pre}api/v1/auth/sendWinnersNew`;
export { sendWinnersApi };

const sendPrizesApi = `${pre}api/v1/auth/sendPrizes`;
export { sendPrizesApi };

const sendScoreApi = `${pre}api/v1/auth/sendScore`;
export { sendScoreApi };

const sendTermsApi = `${pre}api/v1/sendTerms`;
export { sendTermsApi };

const bigcashImgUrl = `https://www.gameninja.in/html/BigcashPortalData/BigCash.png`;
export { bigcashImgUrl };

const price = `${pre}price`;
export { price };

const loginUser = `${pre}api/v1/login`;
export { loginUser };

const checkUser = `${pre}checkUser`;
export { checkUser };

const subscribeUser = `${pre}api/v1/subscribe`;
export { subscribeUser };

const matchOtp = `${pre}api/v1/MatchOtpRequest`;
export { matchOtp };

const google_key = `G-6243J8J93N`;
export { google_key };

const unsubApi = `${pre}api/v1/auth/unsub`;
export { unsubApi };

const userPosition = `${pre}api/v1/auth/userPosition`;
export { userPosition };